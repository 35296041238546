import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const IndexPage = () => (
    <Layout>
        <Seo title="Recruit"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="Recruit">採用情報</h1>

            <div>
                <p>一緒に会社を育てていく仲間を募集しています。</p>
            </div>

            <hr style={hrStyle}/>

            <div>
                <h4>Asterisk/VoIPエンジニア</h4>
                <table>
                    <colgroup>
                        <col width="20%"/>
                        <col width="80%"/>
                    </colgroup>
                    <tr>
                        <td style={leftTdStyle}>仕事内容</td>
                        <td>■ テレコミュニケーション製品、クラウドＰＢＸなどのシステム・アプリ開発業務<br/>
                            ・コールセンターなどで利用されるシステムや自社プロダクトのクラウドPBXについて、システム・アプリの開発および運用保守<br/>
                            ・システムに不具合があった際に、原因となる箇所を特定し、修正<br/>
                            ・お客様との仕様やスケジュールについて調整<br/>
                            <br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>就業場所</td>
                        <td>■リモートワーク
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>年齢</td>
                        <td>■ 不問</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>学歴</td>
                        <td>■ 不問</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>必要な経験等</td>
                        <td>■ AsteriskやFreeSwitch, Kamailioを利用したシステム構築・運用経験 <br/>
                            ■ Webシステム（PHP，Python, Node.js）や<br/>
                            Android，iPhoneアプリ, 及び Windows, Macアプリ等の開発・保守運用経験がある方優遇<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>賃金・手当</td>
                        <td>■ 応相談</td>
                    </tr>
                </table>
            </div>

            {/*<hr style={hrStyle}/>*/}
            {/*<div>*/}
            {/*    <h4>営業</h4>*/}
            {/*    <table>*/}
            {/*        <colgroup>*/}
            {/*            <col width="20%"/>*/}
            {/*            <col width="80%"/>*/}
            {/*        </colgroup>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>仕事内容</td>*/}
            {/*            <td>■ テレコミュニケーション製品、クラウドPBXなどの営業・販売<br/>*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>雇用形態</td>*/}
            {/*            <td>■ 正社員</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>就業場所</td>*/}
            {/*            <td>■ 事業所所在地と同じ。<br/>*/}
            {/*                〒231-0005 神奈川県横浜市中区本町三丁目24番地1 本町中央ビル 701B*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>年齢</td>*/}
            {/*            <td>■ 不問</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>学歴</td>*/}
            {/*            <td>■ 不問</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>必要な経験等</td>*/}
            {/*            <td>■ 製品営業経験<br/>*/}
            {/*                電話・通信・クラウドPBX製品に関わる経験優遇</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>試用期間</td>*/}
            {/*            <td>■ ３ヵ月</td>*/}
            {/*        </tr>*/}
            {/*        <tr>*/}
            {/*            <td style={leftTdStyle}>賃金・手当</td>*/}
            {/*            <td>■ 月額 200,000 - 600,000円<br/>*/}
            {/*                通勤手当全額支給(上限3万円まで)</td>*/}
            {/*        </tr>*/}
            {/*    </table>*/}
            {/*</div>*/}
            <hr style={hrStyle}/>

            <ContactUs/>
        </div>
    </Layout>
)

export default IndexPage
